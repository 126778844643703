const config = {
  // in epoch seconds
  endingTime: 1622374200,
  funderName: 'Kanav Gupta',
  wager: 0.1,
  funderWallet: '0xdeadbeef',
  contractAddress: '0xcafebabe',
};

export default config;
